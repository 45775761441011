<template>
	<div>
		<div class="person_infor">
			<div class="login_top">
				<div class="img"> <img src="@/assets/login/flag.png"></div>
				<div class="login_title">{{$t('set.bindingPhone')}}</div>
			</div>
		</div>
		<div class="cur_account"></div>
		<div class="input_item">
			<div class="input_item_icon">
				<img src="../../../assets/login/phone.png">
			</div>
			<div class="dropdown">
				<el-dropdown @command="areaCodeClick">
					<span class="el-dropdown-link">
						{{areaCode}}<i class="el-icon-caret-bottom el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item :command="item" v-for="(item,index) in areaCodeList" :key="index">{{item}}
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
			<div class="input">
				<input type="text" v-model="mobile" :placeholder="$t('login.inputPhone')">
			</div>
		</div>
		<div class="input_item">
			<div class="input_item_icon">
				<img src="../../../assets/login/code.png">
			</div>
			<div class="input">
				<input type="text" v-model="code" maxlength="6" :placeholder="$t('login.inputCode')">
			</div>
			<div class="code">
				<div class="code1" @click="getCode" v-if="!isGetCode">{{$t('login.getCode')}}</div>
				<div class="code2" v-else>{{$t('login.sendAgain')}}：{{time}}</div>
			</div>
		</div>
		<div class="btn" @click="confirmClick">
			{{$t('confirm')}}
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				mobile: '',
				code: '',
				areaCodeList: [],
				areaCode: '',

				time: 60,
				isGetCode: false, //是否获取验证码
				timer: '', //定时器
			}
		},
		mounted() {
			this.getAreaCode();

			
		},
		destroyed() {
			clearInterval(this.timer);
		},
		methods: {
			// 获取区号
			getAreaCode() {
				this.$http.getAreaCode().then(res => {
					if (res.code == 1) {
						this.areaCodeList = res.data;
						this.areaCode = res.data[0];
					} else {
						this.$message.error(res.data.msg);
					}
					console.log(res);
				}).catch((v) => {
					console.log(v);
				});
			},
			// 区号点击
			areaCodeClick(command) {
				this.areaCode = command;
			},
			// 获取验证码
			getCode() {
				if (!this.mobile) {
					this.$message.info(this.$t('tips.mobilePhoneNumberCannotBeEmpty'))
					return;
				}

				let data = {
					type: 7,
					is_test: 0,
					mobile_code: this.areaCode,
					mobile: this.mobile,
				}

				this.$http.getCode(data).then(res => {
					if (res.code == 1) {
						this.$message.success(this.$t('sendSuccess'));
						this.isGetCode = true;
						this.timer = setInterval(() => {
							if (this.time == 1) {
								clearInterval(this.timer);
								this.time = 60;
								this.isGetCode = false;
							}
							this.time--;
						}, 1000);
					} else {
						this.$message.error(res.msg);
					}
				}).catch((v) => {

				});
			},
			confirmClick() {
				if (!this.mobile) {
					this.$message.info(this.$t('tips.mobilePhoneNumberCannotBeEmpty'))
					return;
				}

				if (!this.code) {
					this.$message.info(this.$t('tips.codeCannotBeEmpty'))
					return;
				}

				this.$http.bindingPhone({
					mobile_code: this.areaCode,
					mobile: this.mobile,
					code: this.code,
					// step: 2,
					type: 7
				}).then(res => {
					if (res.code == 1) {
						this.$message.success(res.msg);
						let newUserinfo = {
							...this.$store.state.userinfo,
							mobile: this.mobile,
						}
						console.log("哈哈哈", newUserinfo);
						this.$store.commit('edituserinfo', newUserinfo);
						this.$router.push({
							path: '/home/person/personInfor',
							query: {
								curCenterIndex: 0,
							},
						})
					} else {
						this.$message.error(res.msg);
					}
				})
			}
		}
	}
</script>

<style scoped="" lang="less">
	.person_infor {
		width: 926px;
	}

	.login_top {
		display: flex;
		padding: 24px;
		border-bottom: 1px solid #F3F5F6;

		.img {
			display: flex;
			align-items: center;

			img {
				width: 18px;
				height: 18px;
			}
		}

		.login_title {
			margin-left: 8px;
			font-weight: bold;
			font-size: 18px;
		}
	}

	.cur_account {
		color: #333333;
		font-size: 16px;
		text-align: left;
		margin-top: 60px;
	}

	.dropdown {
		margin-left: 24px;

		&:hover {
			cursor: pointer;
		}
	}

	.input_item {
		margin-left: 32px;
		margin-bottom: 32px;
		text-align: left;
		display: flex;
		width: 540px;
		padding: 14px 24px;
		border: 1px solid #DEDEDE;
		font-size: 16px;
		position: relative;


		.input_item_icon {
			img {
				width: 20px;
				height: 20px;
			}
		}

		.input {
			padding-left: 16px;

			input {
				width: 400px;
				border: none;
				outline: none;
				font-size: 16px;
			}
		}

		.code {
			position: absolute;
			top: 13px;
			right: 24px;
			color: #4177DD;
			font-size: 16px;

			&:hover {
				cursor: pointer;
			}

		}

		.show_icon {
			position: absolute;
			top: 13px;
			right: 24px;

			img {
				width: 18px;
				height: 18px;
			}
		}
	}

	.btn {
		margin-left: 32px;
		width: 216px;
		text-align: center;
		height: 48px;
		line-height: 48px;
		background: #F39800;
		opacity: 1;
		border-radius: 24px;
		font-size: 16px;
		color: #FFFFFF;

		&:hover {
			cursor: pointer;
		}
	}

	.el-dropdown-menu {
		// width: 100px;
		max-height: 150px !important;
		overflow-y: scroll;
		overflow-x: hidden;
	}
</style>
